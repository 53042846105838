import { t } from "i18next";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { menu } from "../../../assets/svgs";
import Sidebar from "../../../common/common/Sidebar";
import { selectorActions } from "../../../store";
import SettingsMenu from "./SettingsMenu";

const SettingsSidebar = ({ className }) => {
  const dispatch = useDispatch();

  const state = useSelector(
    (state) => state.selectors.sidebarsState,
    shallowEqual
  );

  return (
    <div
      className={`${className} lg:hidden font-bold text-lg
     flex justify-between items-center`}
    >
      {t("settings")}

      <div
        className="cursor-pointer"
        onClick={() =>
          dispatch(
            selectorActions.setSidebarsState({
              name: "settingsMenuSidebar",
              value: true,
            })
          )
        }
      >
        {menu}
      </div>

      <Sidebar isOpen={state?.settingsMenuSidebar}>
        <SettingsMenu />
      </Sidebar>
    </div>
  );
};

export default SettingsSidebar;
